<form class="main-container">
  <div class="title">
    <h1>Alterar vencimento do título</h1>
  </div>
  <p>{{ data.nameLead }}</p>
  <p class="label">{{ data.cnpjLead | cnpj }}</p>
  <p>
    Vencimento Atual:
    <span class="label-value">{{ data.dueDate }}</span>
  </p>
  <hr />
  <div class="value-info">
    <p>Informe a nova data de vencimento:</p>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Selecione uma data</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [matDatepickerFilter]="filterDates"
        [formControl]="newDate"
        [(ngModel)]="selectedDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="button">
    <button class="actions-cancel" mat-flat-button (click)="modalClosedAlert()">
      Cancelar
    </button>
    <button
      class="actions"
      mat-flat-button
      [ngStyle]="{
        'background-color': '#CC3179'
      }"
      [ngClass]="{ 'button-disabled': newDate.invalid }"
      [disabled]="newDate.invalid"
      (click)="updateExpirationDate()"
    >
      Confirmar
    </button>
  </div>
</form>
