import { Pipe, PipeTransform } from '@angular/core';
import { TypeTitleOptions } from '../utils/operationType-list';

@Pipe({
  name: 'titleType',
})
export class TitleTypePipe implements PipeTransform {
  typesTitle = TypeTitleOptions.list();

  transform(type: string) {
    return this.typesTitle.find((titleType) => titleType.value === type)?.name;
  }
}
