<div class="container-fluid" *ngIf="!isLoading && !hasError">
  <div class="row">
    <section class="col">
      <div class="row">
        <app-card
          [icon]="'bar_chart'"
          [title]="'Meu Valor operado hoje'"
          [subtitle]="
            (draweeReport.myValuesOperatedToday | currency : 'BRL') || 'N/A'
          "
          [apparance]="'black'"
          class="col"
        >
        </app-card>
        <app-card
          [icon]="'monetization_on'"
          [title]="'Valor operado'"
          [subtitle]="
            (draweeReport.totalValueOperated | currency : 'BRL') || 'N/A'
          "
          [apparance]="'default'"
          class="col"
        >
        </app-card>
      </div>
    </section>
    <section class="col">
      <div class="row">
        <app-card
          [icon]="'warning'"
          [title]="'Em atraso '"
          [subtitle]="
            (draweeReport.buyerOperatedValues?.expired | currency : 'BRL') ||
            'N/A'
          "
          [apparance]="'danger'"
          class="col"
        >
        </app-card>
        <app-card
          [icon]="'monetization_on'"
          [title]="'A vencer'"
          [subtitle]="
            (draweeReport.buyerOperatedValues?.toExpire | currency : 'BRL') ||
            'N/A'
          "
          [apparance]="'default'"
          class="col"
        >
        </app-card>
      </div>
    </section>
  </div>
  <div class="title-my-summary">Resumo Minha Carteira</div>

  <div class="mt-4 p-2 row">
    <section class="col">
      <app-card
        [icon]="'event'"
        [title]="' Valores operados pela minha empresa'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <table>
            <tr>
              <th>Com Cedente e Sacado do título</th>
            </tr>
            <tr>
              <td>
                <app-graphic
                  [chartData]="data"
                  [chartType]="'bar'"
                ></app-graphic>
              </td>
            </tr>
          </table>
        </div>
      </app-card>
    </section>

    <section class="col">
      <app-card
        [icon]="'event'"
        [title]="'Recebíveis a vencer'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <table class="width-table">
            <tr>
              <th>Com todos os Cedentes</th>
            </tr>
            <tr>
              <td>Até 30 dias</td>
              <td>
                {{
                  (draweeReport.receivablesToExpire?.valueUpTo30Days
                    | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>31-60 dias</td>
              <td>
                {{
                  (draweeReport.receivablesToExpire?.valueUpTo60Days
                    | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>61-90 dias</td>
              <td>
                {{
                  (draweeReport.receivablesToExpire?.valueUpTo90Days
                    | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>Acima de 91 dias</td>
              <td>
                {{
                  (draweeReport.receivablesToExpire?.valueMoreThan91Days
                    | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>
          </table>
          <hr />
          <table>
            <tr>
              <td>Total a vencer</td>
              <td>
                {{
                  (draweeReport.receivablesToExpire?.totalValue
                    | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>
          </table>
        </div>
      </app-card>
    </section>

    <section class="col">
      <app-card
        [icon]="'verified'"
        [title]="'Recebíveis Liquidados'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <b>Com todos os Cedentes</b>
          <table class="mb-4 width-table">
            <tr>
              <td>Liquidados em dia</td>
              <td>
                {{
                  draweeReport.totalReceivablesOperatedOnPlataform
                    ?.settledOnMyWallet
                }}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Quantidade</td>
              <td>
                {{
                  draweeReport.totalReceivablesOperatedOnPlataform
                    ?.settledOnPlataform?.quantity || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>Valor liquidado</td>
              <td>
                {{
                  (draweeReport.totalReceivablesOperatedOnPlataform
                    ?.settledOnPlataform?.value | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>
          </table>
          <hr />
          <table>
            <tr>
              <td>Liquidados em atraso</td>
              <td>
                {{
                  draweeReport.totalReceivablesOperatedOnPlataform
                    ?.lateOnMyWallet
                }}
              </td>
            </tr>
            <tr>
              <td>Quantidade</td>
              <td>
                {{
                  draweeReport.totalReceivablesOperatedOnPlataform
                    ?.lateOnPlataform?.quantity || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>Valor liquidado</td>
              <td>
                {{
                  (draweeReport.totalReceivablesOperatedOnPlataform
                    ?.lateOnPlataform?.value | currency : "BRL") || "N/A"
                }}
              </td>
              <td></td>
            </tr>
          </table>
        </div>
      </app-card>
    </section>
  </div>
</div>

<div
  *ngIf="isLoading || hasError"
  class="d-flex justify-content-center align-items-center container-fluid"
>
  <mat-spinner *ngIf="isLoading && !hasError"></mat-spinner>
  <b *ngIf="hasError" class="text-muted h6"
    >Ocorreu um erro ao exibir os dados.</b
  >
</div>
