import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { Output, EventEmitter } from '@angular/core';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { debounceTime, of, Subscription, switchMap, take } from 'rxjs';
import { maskCnpj } from 'src/app/utils/string-to-cnpj-mask';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  formFilters!: FormGroup;
  @Output() filterEvent = new EventEmitter<FiltersInterface>();
  @Input() tabStatus!: string | null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  actorClientOptions: Record<string, { name: string; cnpj: string; id: string }[]> = {
    actorOne: [],
    actorTwo: [],
  };
  private subscriptions: Subscription[] = [];

  constructor(private buyerDashboardService: buyerDashboardService) {
    this.formFilters = new FormGroup({
      nameActorOne: new FormControl(null),
      nameActorTwo: new FormControl(null),
      numberInvoice: new FormControl(null),
      dueDateInit: new FormControl(null),
      dueDateEnd: new FormControl(null),
    });

    this.onSubscribeField('nameActorOne', 'ACTOR_ONE');
    this.onSubscribeField('nameActorTwo', 'ACTOR_TWO');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSubscribeField(field: string, actor: 'ACTOR_ONE' | 'ACTOR_TWO') {
    const subscription = this.formFilters.controls[field].valueChanges
    .pipe(
      debounceTime(500),
      switchMap((data: string): any => {
        if (
          data &&
          data?.trimStart() !== '' &&
          data?.trimEnd() !== '' &&
          data?.length > 2
        ) {
          this.getSearchFomento(actor, this.removeSpecialChars(data));
        }

        return of('');
      })
    )
    .subscribe();

    this.subscriptions.push(subscription);
  }

  getSearchFomento(field: 'ACTOR_ONE' | 'ACTOR_TWO' | 'ALL', filter?: string) {
    const type = ( field === 'ACTOR_ONE') ? 'LEAD' : 'SACADO';

    this.buyerDashboardService
      .getSearchFomento(type, filter)
      .pipe(take(1))
      .subscribe((res) => {
        if (field === 'ACTOR_ONE') {
          this.actorClientOptions = { ...this.actorClientOptions, actorOne: res.content};
        }

        if (field === 'ACTOR_TWO') {
          this.actorClientOptions = { ...this.actorClientOptions, actorTwo: res.content};
        }
      });
  }

  removeSpecialChars(text: string) {
    return text.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9\s]/g, '');
  }

  getFormatedForm() {
    const data = this.formFilters.value;

    if (data['dueDateInit'] !== null) {
      data['dueDateInit'] = new Date(data['dueDateInit'])
        .toISOString()
        .split('T')[0];
    }
    if (data['dueDateEnd'] !== null) {
      data['dueDateEnd'] = new Date(data['dueDateEnd'])
        .toISOString()
        .split('T')[0];
    }

    data['companyList'] = [
      {
        cnpj: data.nameActorOne ?? null,
        type: 'LEAD',
      },
      {
        cnpj: data.nameActorTwo ?? null,
        type: 'SACADO',
      },
    ];


    const { nameActorOne, nameActorTwo, typeActorOne, typeActorTwo, ...rest } =
      data;

    return rest;
  }

  displayActor(type: string, cnpj: string) {
    if (!cnpj) return '';

    const name = this.actorClientOptions[type].find((actor) => actor.cnpj === cnpj)?.name;
    return `${name} - ${maskCnpj(cnpj)}`;
  }

  formatedCnpjLabel(cnpj: string) {
    return maskCnpj(cnpj);
  }

  clearFilters() {
    this.formFilters.reset({ status: this.tabStatus });
    this.onSubmit();
  }

  onSubmit() {
    const data = this.getFormatedForm();

    this.filterEvent.emit(data);
  }
}
