import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OperationsComponent } from './pages/operations/operations.component';
import { AngularMaterialModule } from './modules/angular-material.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SimulateReceivablesModalComponent } from './components/simulate-receivables-modal/simulate-receivables-modal.component';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { InsufficientCreditModalComponent } from './components/insufficient-credit-modal/insufficient-credit-modal.component';
import { AnticipationRealizedModalComponent } from './components/anticipation-realized-modal/anticipation-realized-modal.component';
import { SignatureDocumentsModalComponent } from './components/signature-documents-modal/signature-documents-modal.component';
import { AnalysisTitlesViewComponent } from './components/analysis-titles-view/analysis-titles-view.component';
import { ForSaleComponent } from './components/for-sale/for-sale.component';

import { DocumentsTitlesComponent } from './components/documents-titles/documents-titles.component';
import { FiltersComponent } from './components/filters/filters.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalRegisterCedenteComponent } from './components/modal-register-cedente/modal-register-cedente.component';
import { FinancialComponent } from './pages/financial/financial.component';
import { PaidComponent } from './components/paid/paid.component';
import { SettledComponent } from './components/settled/settled.component';
import { InCollectionComponent } from './components/in-collection/in-collection.component';
import { AditivoModalComponent } from './components/aditivo-modal/aditivo-modal.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LOCALE_ID } from '@angular/core';

import localePt from '@angular/common/locales/pt';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { DocViewerComponent } from './components/document-viewer/document-viewer.component';
import { AsideComponent } from './components/aside/aside.component';
import { ModalLiquidatedComponent } from './components/modal-liquidated/modal-liquidated.component';
import { ModalLiquidatedPartinalComponent } from './components/modal-liquidated-partinal/modal-liquidated-partinal.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { FinancialDetalhesComponent } from './components/financial-detalhes/financial-detalhes.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NotifierComponent } from './components/notifier/notifier.component';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { BankingInformationModalComponent } from './components/banking-information-modal/banking-information-modal.component';
import { AssignorSnackbarComponent } from './components/assignor-snackbar/assignor-snackbar.component';
import { CardComponent } from './components/card/card.component';
import { OperationalDataComponent } from './pages/operations/components/operational-data/operational-data.component';
import { CedenteDataComponent } from './pages/operations/components/cedente-data/cedente-data.component';
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
  CurrencyMaskModule,
} from 'ng2-currency-mask';
import { DraweeDataComponent } from './pages/operations/components/drawee-data/drawee-data.component';
import { MySummaryComponent } from './pages/operations/components/my-summary/my-summary.component';
import { NgChartsModule } from 'ng2-charts';
import { GraphicComponent } from './components/graphic/graphic.component';
import { TitleTypePipe } from './pipes/translate-titles-type.pipe';
import { ExtensionTitlesModalComponent } from './components/extension-titles-modal/extension-titles-modal.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
registerLocaleData(localePt);
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};
@NgModule({
  declarations: [
    AppComponent,
    OperationsComponent,
    SimulateReceivablesModalComponent,
    InsufficientCreditModalComponent,
    AnticipationRealizedModalComponent,
    SignatureDocumentsModalComponent,
    AnalysisTitlesViewComponent,
    ForSaleComponent,
    DocumentsTitlesComponent,
    FiltersComponent,
    ModalRegisterCedenteComponent,
    FinancialComponent,
    PaidComponent,
    SettledComponent,
    InCollectionComponent,
    AditivoModalComponent,
    NotAuthorizedComponent,
    DocViewerComponent,
    AsideComponent,
    ModalLiquidatedComponent,
    ModalLiquidatedPartinalComponent,
    FinancialDetalhesComponent,
    NotifierComponent,
    CnpjPipe,
    BankingInformationModalComponent,
    AssignorSnackbarComponent,
    CardComponent,
    OperationalDataComponent,
    CedenteDataComponent,
    DraweeDataComponent,
    MySummaryComponent,
    GraphicComponent,
    TitleTypePipe,
    ExtensionTitlesModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    InputMaskModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    CurrencyMaskModule,
    NgChartsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    CurrencyPipe,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
