<div class="container">
  <h1>Dados Bancários</h1>
  <p class="text-alternative">
    O pagamento da operação deve ser efetuado na conta:
  </p>
  <div class="banks-content">
    <p>
      Banco: <span>{{ data.name }} </span>
    </p>
    <p>
      Agência: <span>{{ data.agency }}</span>
    </p>
    <p>
      Conta Corrente: <span>{{ data.account }} - {{ data.accountDigit }}</span>
    </p>
    <p>
      Pix: <span>{{ data.pix || "-" }}</span>
    </p>
  </div>
  <div class="confirmation-checkbox">
    <mat-checkbox
      data-cy="awaiting-signature-confirm-bank-data-checkbox"
      [formControl]="confirmation" class="example-margin"
      >Confirmo que estou ciente dos dados bancários para
      pagamento.</mat-checkbox
    >
    <button
      data-cy="awaiting-signature-confirmar-pagamento-modal-button"
      mat-flat-button
      class="button-simulate"
      (click)="confirm()"
      [ngStyle]="{
        'background-color': '#CC3179',
        opacity: confirmation.value ? 1 : 0.5
      }"
      [disabled]="!confirmation.value"
    >
      Confirmar pagamento
    </button>
  </div>
</div>
