import { Component, Inject } from '@angular/core';
import { FormControl, FormControlState, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  ChangeDueDateTitleInterface,
  UpdateExpirationDatePayload,
} from 'src/app/interfaces/installment.interface';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-extension-titles-modal',
  templateUrl: './extension-titles-modal.component.html',
  styleUrls: ['./extension-titles-modal.component.css'],
})
export class ExtensionTitlesModalComponent {
  selectedDate!: Date; // Guarda a data selecionada pelo usuário
  newDate = new FormControl('', Validators.required);

  constructor(
    private buyerDashboardService: buyerDashboardService,
    private notifierService: NotifierService,
    public dialogRef: MatDialogRef<ExtensionTitlesModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ChangeDueDateTitleInterface
  ) {}
  filterDates = (d: Date | null): boolean => {
    const actualDate = new Date();
    actualDate.setHours(0, 0, 0, 0);
    let dueDate = actualDate;
    if (this.data.dueDate)
      dueDate = new Date(
        (this.data.dueDate as string).split('/').reverse().join('-') +
          'T00:00:00'
      );
    dueDate.setHours(0, 0, 0, 0);

    if (dueDate < actualDate) dueDate = actualDate;

    return d ? d > dueDate : false;
  };

  modalClosedAlert() {
    const confirmation = confirm(
      'Ao sair sem salvar, a nova data de vencimento não será salva.'
    );

    if (confirmation) this.dialogRef.close();
  }
  updateExpirationDate() {
    const params: UpdateExpirationDatePayload = {
      installmentId: this.data.installmentId,
      responsible: this.data.responsible,
      newExpirationDate: new Date(this.newDate.value as unknown as Date)
        .toISOString()
        .split('T')[0],
    };
    this.buyerDashboardService.updateExpirationDate(params).subscribe(() => {
      this.notifierService.showNotification({
        message: 'Dados Alterados.',
        alternative: ' Data alterada com sucesso!',
        messageType: 'successNotifier',
      });
      this.dialogRef.close({ success: true });
    });
  }
}
