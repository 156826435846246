import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { map, take, finalize } from 'rxjs';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';

import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { ModalRegisterCedenteComponent } from '../modal-register-cedente/modal-register-cedente.component';
import { OperationTypeList } from 'src/app/utils/operationType-list';
import { AuthService } from 'src/app/services/auth.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { SourceEnum } from 'src/app/interfaces/confirmationDrawee.interface';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-for-sale',
  templateUrl: './for-sale.component.html',
  styleUrls: ['./for-sale.component.scss'],
})
export class ForSaleComponent implements OnInit {
  pageIndex: number = 0;
  pageSize: number = 20;
  isLoading = false;
  leads: OperationLead[] = [];
  dataSource = new MatTableDataSource<OperationLead>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalFaceValue: number = 0;
  customerStatus = 'FOR_SALE';
  filters?: FiltersInterface = { status: this.customerStatus };
  sourceOptions = SourceEnum;
  displayedColumns: string[] = [
    'select',
    'confirmationDrawee',
    'Cedente',
    'Sacado',
    'Broker',
    'TipoOperacao',
    'Parcela',
    'responsável',
    'Vencimento',
    'Tipo',
    'Taxa',
    'Numero',
    'Valor de face',
    'Valor líquido',
    'Observacao',
  ];
  data!: OperationLead[];
  selection = new SelectionModel<OperationLead>(true, []);
  currentExpandedItem?: OperationLead;
  lastPage = false;

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.leads.length;
    return numSelected === numRows;
  }

  filterData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.leads);
  }

  openDialogRegisterCedente(row: OperationLead) {
    const { cnpjLead, cnpjSacado } = row;

    const dialogRef = this.dialog.open(ModalRegisterCedenteComponent, {
      data: { cnpj: cnpjLead, cnpjSacado },
    });
  }
  constructor(
    private buyerDashboardService: buyerDashboardService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private notifierService: NotifierService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getCustomers();
  }

  changeTitleStatus(status: string) {
    const selectedItems = this.selection.selected;
    const installments = selectedItems.map((i) => i.id);
    const buyerId = this.authService.getAuth().auth;

    const requestData = {
      installments,
      status,
      buyerId,
    };

    this.buyerDashboardService
      .updateInstallments(requestData)
      .pipe(take(1))
      .subscribe(() => {
        this.notifierService.showNotification({
          message: 'Dados Alterados.',
          alternative: 'Titulo alterado com Sucesso!',
          messageType: 'successNotifier',
        });
        this.selection.clear();
        this.onRefresh();

      });
  }

  getTotalFaceValue() {
    this.buyerDashboardService.getTotalFaceValue(this.filters).subscribe({
      next: (totalFaceValue) => {
        this.totalFaceValue = totalFaceValue;
      },
    });
  }

  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.buyerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      });
  }
  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }


  getCustomers(data?: FiltersInterface) {
    if (data) {
      this.filters = {
        status: this.customerStatus,
        ...data,
      };
    }
    if (!this.lastPage) {
      this.isLoading = true;
      this.buyerDashboardService
        .getAll(this.pageIndex, this.pageSize, this.filters)
        .pipe(
          map(({ content, ...rest }) => {
            const invoicesWithWrongInstallments =
              this.checkInvoicesWithWrongInstallments(content);

            if (invoicesWithWrongInstallments?.length) {
              invoicesWithWrongInstallments.forEach((invoiceNumber) => {
                content = content.map((invoice) => {
                  return this.correctInvoiceInstallments(
                    invoice,
                    invoiceNumber
                  );
                });
              });
            }
            return { content, ...rest };
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (data) => {
            if (this.pageIndex === 0) this.leads = data.content;
            else this.leads = this.leads.concat(data.content);
            if (!data.last) this.pageIndex = this.pageIndex + 1;
            if (data.last) this.lastPage = true;
            this.getTotalFaceValue();
          },
        });
    }
  }

  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }
  handleSubmit(data?: FiltersInterface) {
    this.pageIndex = 0;
    this.lastPage = false;
    this.getCustomers(data);
  }

  clearSelection() {
    this.selection.clear();
  }

  handlePressRow(row: OperationLead) {
    this.currentExpandedItem =
      this.currentExpandedItem !== row ? row : undefined;
  }

  closeAside() {
    this.currentExpandedItem = undefined;
  }

  translateStatus(status: string) {
    switch (status) {
      case 'FOR_SALE':
        return 'À Venda';
      case 'IN_ANALYSIS':
        return 'Em Análise';
      case 'FOR_SALE':
        return 'SETTLED';
      case 'Liquidado':
        return 'Pagos';
      case 'IN_COLLECTION':
        return 'Em Cobrança';
      default:
        return 'Aguardando Assinatura';
    }
  }

  navigateTo(url: string) {
    this.router.navigate([url], {
      relativeTo: this.route,
      state: { item: this.currentExpandedItem },
    });
  }

  checkInvoicesWithWrongInstallments(
    invoice: OperationLead[]
  ): OperationLead['numberInvoice'][] {
    return invoice
      .filter((invoice) => {
        const installmentAsString = String(invoice.numberInstallment);
        const hasWrongInstallment =
          installmentAsString.startsWith('0') &&
          installmentAsString.charAt(1) === '/';
        return hasWrongInstallment;
      })
      .map((invoice) => invoice.numberInvoice);
  }

  correctInvoiceInstallments(
    invoice: OperationLead,
    invoiceNumber: OperationLead['numberInvoice']
  ) {
    if (invoice.numberInvoice === invoiceNumber) {
      const numberInstallmentAsString = String(invoice.numberInstallment);
      const [firstHalf, secondHalf] = numberInstallmentAsString.split('/');
      const correctNumber = +firstHalf + 1;
      const correctInstallmentNumber = String(correctNumber).concat(
        '/',
        secondHalf
      );
      return { ...invoice, numberInstallment: correctInstallmentNumber };
    }
    return { ...invoice };
  }

  onRefresh() {
    this.pageIndex = 0;
    this.lastPage = false;
    this.leads = [];
    this.getCustomers();
  }
}
