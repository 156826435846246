<div class="container-fluid" *ngIf="!isLoading && !hasError">
  <div class="row">
    <section class="col">
      <div class="mb-2"><b>Valores operados do Sacado</b></div>
      <div class="row">
        <app-card
          [icon]="'monetization_on'"
          [title]="'A vencer na minha carteira'"
          [subtitle]="
            (platformDraweeReport.draweeOperatedValuesWithThisBuyer?.toExpire
              | currency : 'BRL') || 'N/A'
          "
          [apparance]="'black'"
          class="col"
        >
        </app-card>
        <app-card
          [icon]="'warning'"
          [title]="'Em atraso na minha carteira'"
          [subtitle]="
            (platformDraweeReport.draweeOperatedValuesWithThisBuyer?.expired
              | currency : 'BRL') || 'N/A'
          "
          [apparance]="'danger'"
          class="col"
        >
        </app-card>
      </div>
    </section>
    <section class="col">
      <div class="mb-2"><b>Valores operados do Sacado na plataforma</b></div>
      <div class="row">
        <app-card
          [icon]="'monetization_on'"
          [title]="'A vencer na plataforma'"
          [subtitle]="
            (platformDraweeReport.draweeOperatedValuesOnPlataform?.toExpire
              | currency : 'BRL') || 'N/A'
          "
          [apparance]="'black'"
          class="col"
        >
        </app-card>
        <app-card
          [icon]="'warning'"
          [title]="'Em atraso na plataforma'"
          [subtitle]="
            (platformDraweeReport.draweeOperatedValuesOnPlataform?.expired
              | currency : 'BRL') || 'N/A'
          "
          [apparance]="'danger'"
          class="col"
        >
        </app-card>
      </div>
    </section>
  </div>

  <div class="mt-4 p-2 row">
    <section class="col">
      <app-card
        [icon]="'event'"
        [title]="'Última operação realizada em'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <span>{{
            (platformDraweeReport?.lastOperationDate | date : "dd/MM/YYYY") ||
              "N/A"
          }}</span>
        </div>
      </app-card>

      <app-card
        [icon]="'event'"
        [title]="'Recebíveis a vencer'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <table class="width-table">
            <tr class="small">
              <th>&nbsp;</th>
              <th>Na minha carteira</th>
              <th>Na plataforma</th>
            </tr>
            <tr>
              <td>0-30 dias</td>
              <td>
                {{
                  (platformDraweeReport.installmentsToExpire?.myWallet
                    ?.valueUpTo30Days | currency : "BRL") || "N/A"
                }}
              </td>
              <td>
                {{
                  (platformDraweeReport.installmentsToExpire?.plataform
                    ?.valueUpTo30Days | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>31-60 dias</td>
              <td>
                {{
                  (platformDraweeReport.installmentsToExpire?.myWallet
                    ?.valueUpTo60Days | currency : "BRL") || "N/A"
                }}
              </td>
              <td>
                {{
                  (platformDraweeReport.installmentsToExpire?.plataform
                    ?.valueUpTo60Days | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>61-90 dias</td>
              <td>
                {{
                  (platformDraweeReport.installmentsToExpire?.myWallet
                    ?.valueUpTo90Days | currency : "BRL") || "N/A"
                }}
              </td>
              <td>
                {{
                  (platformDraweeReport.installmentsToExpire?.plataform
                    ?.valueUpTo90Days | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>Mais de 91 dias</td>
              <td>
                {{
                  (platformDraweeReport.installmentsToExpire?.myWallet
                    ?.valueMoreThan91Days | currency : "BRL") || "N/A"
                }}
              </td>
              <td>
                {{
                  (platformDraweeReport.installmentsToExpire?.plataform
                    ?.valueMoreThan91Days | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>
          </table>
        </div>
      </app-card>
    </section>

    <section class="col">
      <app-card
        [icon]="'verified'"
        [title]="'Recebíveis Liquidados'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <b>Em dia</b>
          <table class="mb-4 width-table">
            <tr class="small">
              <th>&nbsp;</th>
              <th>Na Minha Carteira</th>
              <th>Na Plataforma</th>
            </tr>
            <tr>
              <td>Quantidade</td>
              <td>
                {{
                  platformDraweeReport.settledInstallments?.settledOnMyWallet
                    ?.quantity || "N/A"
                }}
              </td>
              <td>
                {{
                  platformDraweeReport.settledInstallments?.settledOnPlataform
                    ?.quantity || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>Valor liquidado</td>
              <td>
                {{
                  (platformDraweeReport.settledInstallments?.settledOnMyWallet
                    ?.value | currency : "BRL") || "N/A"
                }}
              </td>
              <td>
                {{
                  (platformDraweeReport.settledInstallments?.settledOnPlataform
                    ?.value | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>
          </table>

          <b>Em atraso</b>
          <table>
            <tr class="small">
              <th>&nbsp;</th>
              <th>Na Minha Carteira</th>
              <th>Na Plataforma</th>
            </tr>
            <tr>
              <td>Quantidade</td>
              <td>
                {{
                  platformDraweeReport.settledInstallments?.lateOnMyWallet
                    ?.quantity || "N/A"
                }}
              </td>
              <td>
                {{
                  platformDraweeReport.settledInstallments?.lateOnPlataform
                    ?.quantity || "N/A"
                }}
              </td>
            </tr>

            <tr>
              <td>Valor liquidado</td>
              <td>
                {{
                  (platformDraweeReport.settledInstallments?.lateOnMyWallet
                    ?.value | currency : "BRL") || "N/A"
                }}
              </td>
              <td>
                {{
                  (platformDraweeReport.settledInstallments?.lateOnPlataform
                    ?.value | currency : "BRL") || "N/A"
                }}
              </td>
            </tr>
          </table>
        </div>
      </app-card>
    </section>

    <section class="col">
      <app-card
        [icon]="'bar_chart'"
        [title]="'Total de recebíveis operados na plataforma'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <div class="w-100">
            <div class="d-flex justify-content-between">
              <span>Quantidade:</span>
              <span>{{
                platformDraweeReport.totalReceivablesOperatedOnPlataform
                  ?.quantity || "N/A"
              }}</span>
            </div>
            <div class="d-flex justify-content-between">
              <span>Valor operado:</span>
              <span
                >{{
                  (platformDraweeReport.totalReceivablesOperatedOnPlataform
                    ?.value | currency : "BRL") || "N/A"
                }}
              </span>
            </div>
          </div>
        </div>
      </app-card>

      <app-card
        [icon]="'percent'"
        [title]="'Concentração do Sacado na minha carteira'"
        [size]="'medium'"
        [hasContent]="true"
        [toolTip]="
          'Concentração dos títulos do sacado na minha carteira, ou seja, percentual de títulos que minha empresa antecipou deste sacado em comparação com todas as operações que realizamos na plataforma.'
        "
      >
        <div content>
          <div class="w-100">
            <div class="d-flex justify-content-between">
              <span>A vencer:</span>
              <span>
                {{
                  (getPercentInfo(
                    platformDraweeReport.percentualOperated
                      ?.totalToExpireWithThisLead ?? 0
                  ) | percent : "1.0-2") || "N/A"
                }}
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <span>Total operado:</span>
              <span>
                {{
                  (getPercentInfo(
                    platformDraweeReport.percentualOperated
                      ?.totalOperatedWithThisLead ?? 0
                  ) | percent : "1.0-2") || "N/A"
                }}</span
              >
            </div>
          </div>
        </div>
      </app-card>
    </section>
  </div>
  <div class="my-4">
    <b>Contato(s) do Sacado</b>
    <div class="row p-2" *ngFor="let contact of contactList">
      <div class="col d-flex flex-column">
        <small class="text-muted">Nome</small>
        <span>{{ contact?.name ?? "-" }}</span>
      </div>
      <div class="col d-flex flex-column">
        <small class="text-muted">E-mail</small>
        <span>{{ contact?.mail ?? "-" }}</span>
      </div>
      <div class="col d-flex flex-column">
        <small class="text-muted">Telefone</small>
        <span>{{ contact?.phone ?? "-" }}</span>
      </div>
    </div>
    <div *ngIf="!contactList.length">
      <span class="text-muted">Nenhum contato cadastrado.</span>
    </div>
  </div>
</div>

<div
  *ngIf="isLoading || hasError"
  class="d-flex justify-content-center align-items-center container-fluid"
>
  <mat-spinner *ngIf="isLoading && !hasError"></mat-spinner>
  <b *ngIf="hasError" class="text-muted h6"
    >Ocorreu um erro ao exibir os dados.</b
  >
</div>
