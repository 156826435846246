import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-graphic',
  templateUrl: './graphic.component.html',
  styleUrls: ['./graphic.component.css'],
})
export class GraphicComponent {
  @Input() chartData: ChartConfiguration<'bar'>['data'] = {
    datasets: [],
    labels: [],
  };
  @Input() chartType: ChartType = 'bar'; // Tipo do gráfico (pode ser 'line', 'pie', etc.)

  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.raw as number;
            return `${label}: R$ ${value.toLocaleString('pt-BR')}`;
          },
        },
      },
      datalabels: {
        formatter: function (value) {
          return `R$ ${(value as number).toLocaleString('pt-BR')}`;
        },
        color: '#000',
      },
    },

    scales: {
      y: {
        display: false, // Remove os números laterais
        grid: {
          display: false, // Remove o fundo (linhas de grade)
        },
      },
      x: {
        grid: {
          display: false, // Remove as linhas de grade horizontais
        },
      },
    },
  };
  public plugins = [ChartDataLabels];

  constructor() {}

  ngOnInit(): void {}
}
