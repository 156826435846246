import { Component } from '@angular/core';
import { ContactCompany } from 'src/app/interfaces/companyData.interface';
import {
  DraweeReportInterface,
  PlatformDraweeReportInterface,
} from 'src/app/interfaces/drawee-report.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';

@Component({
  selector: 'app-drawee-data',
  templateUrl: './drawee-data.component.html',
  styleUrls: ['./drawee-data.component.css'],
})
export class DraweeDataComponent {
  draweeReport!: DraweeReportInterface;
  platformDraweeReport!: PlatformDraweeReportInterface;
  selectedItem!: OperationLead;
  contactList: ContactCompany[] = [];
  isLoading: boolean = false;
  hasError: boolean = false;

  constructor(private service: buyerDashboardService) {
    this.getSelectedItem();
    this.getPlatformDataSacado();
    this.getContact();
  }

  getSelectedItem() {
    this.selectedItem = history.state.item;
  }

  getPlatformDataSacado() {
    this.isLoading = true;
    this.service.getPlatformDataSacado(this.selectedItem?.id).subscribe({
      next: (response) => {
        this.platformDraweeReport = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.hasError = true;
      },
    });
  }

  getContact() {
    this.service.getContactCompany(this.selectedItem.sacadoId).subscribe({
      next: (response) => {
        this.contactList = response;
      },
    });
  }

  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }
}
