<h1 mat-dialog-title>
  Valor Liquido da Operação
  <label
    class="labelHeading"
    matTooltip=""
    matTooltipClass="example-tooltip-red1"
  >
    <mat-icon>help</mat-icon>
  </label>
</h1>

<div mat-dialog-content>
  <div class="current-operations-container">
    <div class="operation-title-container">
      <p class="title-selected">Títulos selecionados</p>
      <p class="title-selected">Valor de Face</p>
    </div>

    <div
      class="operation-title-container"
      *ngFor="let operation of currentOperations"
    >
      <p>{{ operation.nameSacado }}</p>
      <p>
        {{ operation.totalAmount | currency : "BRL" }}
      </p>
    </div>

    <hr />

    <div class="operation-title-container">
      <p class="title-selected">Valor total de face</p>
      <p class="title-selected">
        {{ totalAmount | currency : "BRL" }}
      </p>
    </div>
  </div>

  <div class="fee-container">
    <div class="text-fee">
      <p class="fee">(-) Deságio:</p>
      <p class="fee">{{ discount | currency : "BRL" }}</p>
    </div>
    <div class="text-fee">
      <div class="fee-label-container">
        <p class="fee">(-) Emolumentos:</p>
        <label
          matTooltip="R$ 10,00 por sacado, R$ 5,00 por título e R$ 10,00 por operação"
          ><mat-icon>help</mat-icon>
        </label>
      </div>
      <p class="fee">{{ emoluments | currency : "BRL" }}</p>
    </div>
  </div>
  <div class="operation-title-container result-value-container">
    <p class="title-selected">Valor total líquido</p>
    <p class="title-selected">{{ totalNetValue | currency : "BRL" }}</p>
  </div>
</div>

<mat-dialog-actions align="center">
<button mat-stroked-button mat-dialog-close>Cancelar</button>
   <button
      data-cy="simulate-receivables-comprar-titulos"
      mat-flat-button class="button-simulate"
      (click)="changeTitleStatus()"
      [disabled]="isLoading"
      >
      <div class="d-flex justify-center align-center">
        <ng-container *ngIf="!isLoading">
          Comprar títulos
        </ng-container>

        <ng-container *ngIf="isLoading">
          <mat-icon class="ms-1">
            <mat-spinner
            diameter="20"
            color="accent">
          </mat-spinner>
          </mat-icon>
        </ng-container>
      </div>
  </button>
</mat-dialog-actions>
