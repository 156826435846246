import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { Observable, take } from 'rxjs';
import {
  FinancialHistory,
  FinancialSummary,
  FinancialSummaryContent,
  Lead,
  bodyDetail,
} from '../interfaces/financial.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FinancialService {
  constructor(private httpClient: HttpClient) {}

  getDocumentDowloand(filters: bodyDetail): Observable<any> {
    filters = this.removeNullProperties(filters);

    return this.httpClient.post(
      environment.financial_url + `/api/bill/detail/download`,
      filters,
      { responseType: 'blob' }
    );
  }

  getSummary(date: Moment, tenantId: string): Observable<FinancialSummary> {
    let formatedDate = date.format('DD/MM/YYYY');
    let body = { date: formatedDate, tenantId: tenantId };

    return this.httpClient.post<FinancialSummary>(
      environment.financial_url + `api/bill/summary/0/50`,
      body
    );
  }

  getHistory(
    size: number,
    page: number,
    body: any
  ): Observable<FinancialHistory> {
    body = this.removeNullProperties(body);

    return this.httpClient.post<FinancialHistory>(
      environment.financial_url + `api/bill/detail/${page}/${size}`,
      body
    );
  }

  getTotalDatails(body: any) {
    body = this.removeNullProperties(body);

    return this.httpClient.post<any>(
      environment.financial_url + `api/bill/detail/total`,
      body
    );
  }

  getTenantId() {
    const authString = sessionStorage.getItem('auth');
    const auth = JSON.parse(authString!);
    return auth.auth;
  }

  getCompanyName() {
    const authString = sessionStorage.getItem('auth');
    const name = JSON.parse(authString!);
    return name.desc;
  }

  getDateSelected(): Moment {
    return sessionStorage.getItem('dateSelected')
      ? moment(sessionStorage.getItem('dateSelected'))
      : moment();
  }

  setDateSelected(date: Moment) {
    sessionStorage.setItem('dateSelected', date.format('DD/MM/YYYY'));
  }

  getDataLeads(value: string, type_actor: string): Observable<Lead> {
    value = this.formatFilterEntry(value);

    return this.httpClient.get<Lead>(
      environment.register_api +
        `secure-api/company/getall/${type_actor}/0/50/ALL?filter=${value}`
    );
  }

  private removeNullProperties(obj: any) {
    for (let prop in obj) {
      if (!obj[prop]) delete obj[prop];
    }

    return obj;
  }

  private formatFilterEntry(filter: string): string {
    const hasLetter = /[a-zA-Z]/.test(filter);

    if (!hasLetter) {
      filter = filter.replace(/\D/g, '');
    }

    return filter;
  }
}
