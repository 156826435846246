import { SelectionModel } from '@angular/cdk/collections';
import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { map, take, finalize } from 'rxjs';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { AditivoModalComponent } from '../aditivo-modal/aditivo-modal.component';
import { OperationTypeList } from 'src/app/utils/operationType-list';

import { ModalLiquidatedPartinalComponent } from '../modal-liquidated-partinal/modal-liquidated-partinal.component';
import { ModalRegisterCedenteComponent } from '../modal-register-cedente/modal-register-cedente.component';
import { NotifierService } from 'src/app/services/notifier.service';
import { SourceEnum } from 'src/app/interfaces/confirmationDrawee.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtensionTitlesModalComponent } from '../extension-titles-modal/extension-titles-modal.component';
import {
  ChangeDueDateTitleInterface,
  UpdateExpirationDatePayload,
} from 'src/app/interfaces/installment.interface';

@Component({
  selector: 'app-paid',
  templateUrl: './paid.component.html',
  styleUrls: ['./paid.component.scss'],
})
export class PaidComponent implements OnInit {
  pageIndex: number = 0;
  pageSize: number = 20;
  isLoading = false;
  leads: OperationLead[] = [];
  currentExpandedItem?: OperationLead;
  totalPayment!: number;
  dataSource = new MatTableDataSource<OperationLead>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  customerStatus = 'PAID';
  filters?: FiltersInterface = { status: this.customerStatus };
  sourceOptions = SourceEnum;
  totalFaceValue: number = 0;

  displayedColumns = [
    'select',
    'confirmationDrawee',
    'Cedente',
    'Sacado',
    'Broker',
    'TipoOperacao',
    'Parcela',
    'responsável',
    'Vencimento',
    'Valor em Aberto',
    'Tipo',
    'Numero',
    'Valor de face',
    'Valor líquido',
    'DataOperacao',
    'Aditivo',
    'Observacao',
  ];
  data!: OperationLead[];
  row!: OperationLead;
  selection = new SelectionModel<OperationLead>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row: OperationLead): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      this.dataSource.data.indexOf(row) + 1
    }`;
  }

  openDialogRegisterCedente(item: OperationLead) {
    const { cnpjLead, cnpjSacado } = item;

    const dialogRef = this.dialog.open(ModalRegisterCedenteComponent, {
      data: { cnpj: cnpjLead, cnpjSacado },
    });
  }

  openDialogExtensionOfTitles() {
    const data: Omit<ChangeDueDateTitleInterface, 'newExpirationDate'> = {
      installmentId: this.currentExpandedItem!.id,
      responsible: this.currentExpandedItem!.responsible as string,
      dueDate: this.currentExpandedItem!.dueDate,
      cnpjLead: this.currentExpandedItem!.cnpjLead,
      nameLead: this.currentExpandedItem!.nameLead,
    };
    const dialogRef = this.dialog.open(ExtensionTitlesModalComponent, { data });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.success) {
        this.selection.clear();
        this.handleSubmit();
      }
    });
  }

  currencyKeys = ['totalAmount', 'amountPaid', 'remainingValue', 'amountToPay'];
  getItemValue(item: OperationLead, key: string) {
    let value = item[key as keyof OperationLead];

    if (this.currencyKeys.some((currencyKey) => key === currencyKey)) {
      value = this.currencyPipe.transform(value as string | number, 'BRL');
    }

    return value;
  }

  constructor(
    private buyerDashboardService: buyerDashboardService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private currencyPipe: CurrencyPipe,
    private notifierService: NotifierService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.getCustomers();
  }
  getTotalFaceValue() {
    this.buyerDashboardService.getTotalFaceValue(this.filters).subscribe({
      next: (totalFaceValue) => {
        this.totalFaceValue = totalFaceValue;
      },
    });
  }

  filterData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  lastPage = false;

  getCustomers(data?: FiltersInterface, pageIndex?: number, pageSize?: number) {
    if (data) {
      this.filters = {
        status: this.customerStatus,
        ...data,
      };
    }
    if (!this.lastPage) {
      this.isLoading = true;

      this.buyerDashboardService
        .getAll(this.pageIndex, this.pageSize, this.filters)
        .pipe(
          map(({ content, ...rest }) => {
            const invoicesWithWrongInstallments =
              this.checkInvoicesWithWrongInstallments(content);

            if (invoicesWithWrongInstallments?.length) {
              invoicesWithWrongInstallments.forEach((invoiceNumber) => {
                content = content.map((invoice) => {
                  return this.correctInvoiceInstallments(
                    invoice,
                    invoiceNumber
                  );
                });
              });
            }
            return { content, ...rest };
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (data) => {
            const content = data.content.map((installment) => ({
              ...installment,
              remainingValue:
                installment.totalAmount - installment.amountPaid < 0
                  ? 0
                  : installment.totalAmount - installment.amountPaid,
            }));

            if (this.pageIndex === 0) this.leads = content;
            else this.leads = this.leads.concat(content);
            if (!data.last) this.pageIndex = this.pageIndex + 1;
            if (data.last) this.lastPage = true;
            this.getTotalFaceValue();
          },
        });
    }
  }

  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.buyerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      });
  }

  openDialogAditive(additiveNumber: string) {
    const dialogRef = this.dialog.open(AditivoModalComponent, {
      data: { additiveNumber },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  handlePressRow(row: OperationLead) {
    if (!row) return;
    this.buyerDashboardService.changeHistory(row.id, 'INSTALLMENT').subscribe({
      next: (changeHistory) => {
        this.currentExpandedItem = row;

        if (this.currentExpandedItem?.paymentsHistory) {
          this.totalPayment = this.currentExpandedItem.paymentsHistory
            ?.map((p) => p.value)
            .reduce((total, p) => total + p, 0);
        }
        this.currentExpandedItem.changeHistory = changeHistory;
      },
      error: () => {
        this.notifierService.showNotification({
          message: 'Erro ao alterar status.',
          alternative: 'Erro ao alterar status.',
          messageType: 'errorNotifier',
        });
      },
    });
  }
  handleCloseSidenav() {
    this.currentExpandedItem = undefined;
  }

  openDialogLiquidatedPartinal() {
    const dialogRef = this.dialog.open(ModalLiquidatedPartinalComponent, {
      data: {
        installmentId: this.selection.selected[0]?.id,
        inputValue: this.selection.selected[0]?.amountPaid,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.success) {
        this.selection.clear();
        this.handleSubmit(this.filters);
      }
    });
  }
  handleSubmit(data?: FiltersInterface) {
    this.pageIndex = 0;
    this.lastPage = false;
    this.leads = [];
    this.getCustomers(data);
  }
  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }
  liquidate() {
    if (this.selection.selected.length) {
      const installmentValue =
        this.selection.selected[0]?.totalAmount?.toString();

      this.buyerDashboardService
        .updatePartial({
          installment: this.selection.selected[0].id,
          value: installmentValue as string,
        })
        .subscribe(() => {
          this.notifierService.showNotification({
            message: 'Dados Alterados.',
            alternative: 'Valor de face liquidado com sucesso!',
            messageType: 'successNotifier',
          });
          this.selection.clear();
          this.onRefresh();
        });
    }
  }
  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }
  navigateTo(url: string) {
    this.router.navigate([url], {
      relativeTo: this.route,
      state: { item: this.currentExpandedItem },
    });
  }

  checkInvoicesWithWrongInstallments(
    invoice: OperationLead[]
  ): OperationLead['numberInvoice'][] {
    return invoice
      .filter((invoice) => {
        const installmentAsString = String(invoice.numberInstallment);
        const hasWrongInstallment =
          installmentAsString.startsWith('0') &&
          installmentAsString.charAt(1) === '/';
        return hasWrongInstallment;
      })
      .map((invoice) => invoice.numberInvoice);
  }

  correctInvoiceInstallments(
    invoice: OperationLead,
    invoiceNumber: OperationLead['numberInvoice']
  ) {
    if (invoice.numberInvoice === invoiceNumber) {
      const numberInstallmentAsString = String(invoice.numberInstallment);
      const [firstHalf, secondHalf] = numberInstallmentAsString.split('/');
      const correctNumber = +firstHalf + 1;
      const correctInstallmentNumber = String(correctNumber).concat(
        '/',
        secondHalf
      );
      return { ...invoice, numberInstallment: correctInstallmentNumber };
    }
    return { ...invoice };
  }

  onRefresh() {
    this.pageIndex = 0;
    this.lastPage = false;
    this.leads = [];
    this.getCustomers();
  }
}
