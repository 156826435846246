<div class="main-container">
  <app-filters
    tabStatus="IN_ANALYSIS"
    (filterEvent)="handleSubmit($event)"
  ></app-filters>
  <section
    class="table-container mat-elevation-z8"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="getCustomers()"
    [scrollWindow]="false"
  >
    <table mat-table [dataSource]="leads">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [disabled]="true"> </mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          [matTooltip]="canSelect(row).message!"
          [matTooltipDisabled]="canSelect(row).canSelect"
        >
          <mat-checkbox
            data-cy="analysis-checkbox"
            (click)="$event.stopPropagation()"
            (change)="$event ? select(row) : null"
            [checked]="selection.isSelected(row)"
            [disabled]="!canSelect(row).canSelect || row.hasPending"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="confirmationDrawee">
        <th mat-header-cell *matHeaderCellDef>
          <button
            class="table-button"
            mat-icon-button
            matTooltip="Nota com confirmação do sacado."
          >
            <mat-icon>task</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            class="table-button"
            mat-icon-button
            *ngIf="row.source === sourceOptions.BASE_B"
            matTooltip="Nota com confirmação do sacado."
          >
            <mat-icon>task</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Cedente">
        <th mat-header-cell *matHeaderCellDef>Cedente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameLead ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Sacado">
        <th mat-header-cell *matHeaderCellDef>Sacado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameSacado ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Broker">
        <th mat-header-cell *matHeaderCellDef>Broker</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameBroker ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="TipoOperacao">
        <th mat-header-cell *matHeaderCellDef>Tipo operação</th>
        <td mat-cell *matCellDef="let element">
          {{ getNameTypeOperation(element.operationType) ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Parcela">
        <th mat-header-cell *matHeaderCellDef>Parcela</th>
        <td
          mat-cell
          *matCellDef="let element"
          data-cy="analysis-installment-number"
        >
          {{ element.numberInstallment ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Vencimento">
        <th mat-header-cell *matHeaderCellDef>Vencimento</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dueDate ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Tipo">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.type ?? "N/A" | titleType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Numero">
        <th mat-header-cell *matHeaderCellDef>Número</th>
        <td
          mat-cell
          *matCellDef="let element"
          data-cy="analysis-invoice-number"
        >
          {{ element.numberInvoice ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="responsável">
        <th mat-header-cell *matHeaderCellDef>Responsável</th>
        <td mat-cell *matCellDef="let element">
          {{ element.responsible ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Valor de face">
        <th mat-header-cell *matHeaderCellDef>Valor de face</th>
        <td
          data-cy="analysis-title-valor-face"
          mat-cell
          *matCellDef="let element"
        >
          {{ (element.totalAmount | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Valor líquido">
        <th mat-header-cell *matHeaderCellDef>
          Valor líquido
          <mat-icon
            matTooltip="Valor líquido considerando apenas o deságio! Os emolumentos serão calculados a partir da confirmação da compra."
            >help</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element.amountToPay | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Observacao">
        <th mat-header-cell *matHeaderCellDef>Observação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.obs ?? "N/A" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'row-disabled': !canSelect(row).canSelect }"
        (click)="handlePressRow(row)"
        class="element-row"
        [class.expanded-row]="currentExpandedItem === row"
        [matTooltipPosition]="'left'"
        matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
        [matTooltipDisabled]="!row.hasPending"
        [ngClass]="{ 'table-text-warning': row.hasPending }"
      ></tr>
    </table>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner class="spinner" color="accent"></mat-spinner>
    </div>
    <div class="container-blankslate" *ngIf="!isLoading && leads.length == 0">
      <p>Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
  </section>

  <div class="total-face-value">
    <span>Valor total de face:</span>

    <div>
      <span *ngIf="!isLoading">{{
        totalFaceValue ?? 0 | currency : "BRL"
      }}</span>
      <ngx-skeleton-loader
        *ngIf="isLoading"
        count="1"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': '#595958',
          width: '90px',
          height: '20px',
          margin: '0'
        }"
      >
      </ngx-skeleton-loader>
    </div>
  </div>

  <mat-toolbar class="mat-elevation-z4 selection-header">
    <button
      data-cy="analysis-confirmar-compra-button"
      mat-flat-button
      (click)="openDialog()"
      [disabled]="selection.selected.length === 0 || disableButton"
      class="button-simulate"
      [ngStyle]="{
        'background-color':
          selection.selected.length > 0 && !disableButton
            ? '#CC3179'
            : 'rgba(204, 49, 121, 0.38)'
      }"
    >
      Confirmar compra
    </button>
    <button
      data-cy="analysis-descartar-compra-button"
      mat-flat-button
      class="button-primary"
      (click)="changeTitleStatus('FOR_SALE')"
      *ngIf="selection.selected.length > 0"
    >
      <mat-icon>delete</mat-icon> Descartar
    </button>
    <span class="example-spacer"></span>
    <span *ngIf="selection.selected.length > 0">
      {{ selection.selected.length }}
      <span *ngIf="selection.selected.length === 1"> Título selecionado </span>
      <span *ngIf="selection.selected.length > 1"> Títulos selecionados </span>
    </span>
    <button
      mat-icon-button
      (click)="clearSelection()"
      *ngIf="selection.selected.length > 0"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<app-aside [opened]="!!currentExpandedItem" (isClose)="closeAside()">
  <section header>
    <div class="flex-column">
      <span class="text-black"
        ><strong>{{ currentExpandedItem?.nameLead }}</strong></span
      >
      <span class="text-black">{{ currentExpandedItem?.cnpjLead | cnpj }}</span>
    </div>

    <div class="flex-column">
      <span class="text-black"
        >Número do Título: {{ currentExpandedItem?.numberInvoice }}</span
      >
      <span class="text-black"
        >Taxa:
        {{
          getPercentInfo(currentExpandedItem?.fee!) | percent : "1.0-2"
        }}</span
      >
      <span class="text-black"
        >Valor de Face: {{ currentExpandedItem?.totalAmount }}</span
      >
      <span class="text-black"
        >Valor Líquido: {{ currentExpandedItem?.amountToPay }}</span
      >
    </div>

    <div class="badge-green">Em Análise</div>
  </section>

  <section content>
    <div class="flex-row">
      <button
        mat-stroked-button
        class="sidenav-btn width-50"
        (click)="openDialogRegisterCedente(currentExpandedItem!)"
      >
        <mat-icon>assignment</mat-icon>
        Dados cadastrais
      </button>
      <button
        mat-stroked-button
        class="sidenav-btn width-50"
        (click)="navigateTo('dados-operacionais')"
      >
        <mat-icon>insert_chart</mat-icon>
        Dados operacionais
      </button>
    </div>

    <button
      mat-stroked-button
      class="sidenav-btn width-100"
      (click)="
        getDownloadFilesOperation(
          currentExpandedItem!.id,
          currentExpandedItem!.numberInvoice!
        )
      "
    >
      <mat-icon>file_download</mat-icon>
      Baixar NF e documentos adicionais
    </button>
  </section>
</app-aside>
