<form class="main-container">
  <div class="title">
    <h1>Qual valor do título deseja manter liquidado?</h1>
  </div>
  <p class="label">Preencha o valor total recebido do título.</p>
  <p class="label">
    Você já liquidou:
    <span class="label-value-liquited">{{
      data.inputValue | currency : "BRL"
    }}</span>
  </p>

  <div data-cy="modal-liquidated-error-message" class="value-info">
    <mat-form-field appearance="outline" class="w-100">
      <input
        data-cy="modal-liquidated-input"
        currencyMask
        [options]=""
        [min]="0"
        [max]="maxValue"
        matInput
        [formControl]="currencyFC"
      />
      <mat-icon matSuffix>mode_edit</mat-icon>
    </mat-form-field>

    <mat-error *ngIf="currencyFC.invalid">
      O valor deve estar entre R$ 0,00 e {{ maxValue | currency : "BRL" }}
    </mat-error>
  </div>

  <div class="button">
    <button class="actions-cancel" mat-flat-button mat-dialog-close="">
      Cancelar
    </button>
    <button
      data-cy="modal-liquidated-confirm-button"
      class="actions"
      mat-flat-button
      [ngStyle]="{
        'background-color': '#CC3179'
      }"
      (click)="updateInCollection()"
      [disabled]="currencyFC.invalid"
      [ngClass]="{ 'button-disabled': currencyFC.invalid }"
    >
      Confirmar
    </button>
  </div>
</form>
