import { Component, OnInit } from '@angular/core';
import { DraweeReportInterface } from 'src/app/interfaces/drawee-report.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-my-summary',
  templateUrl: './my-summary.component.html',
  styleUrls: ['./my-summary.component.css'],
})
export class MySummaryComponent implements OnInit {
  draweeReport!: DraweeReportInterface;
  selectedItem!: OperationLead;
  isLoading: boolean = true;
  hasError: boolean = false;
  public data: ChartConfiguration<'bar'>['data'] = {
    labels: [''],
    datasets: [],
  };

  constructor(private service: buyerDashboardService) {
    this.getSelectedItem();
    this.getOperationDataSacado();
  }

  getSelectedItem() {
    this.selectedItem = history.state.item;
  }

  getOperationDataSacado() {
    this.isLoading = true;
    this.service.getOperationDataSacado(this.selectedItem?.id).subscribe({
      next: (response) => {
        this.draweeReport = response;
        this.updateChartData();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.hasError = true;
      },
    });
  }

  ngOnInit() {}

  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }
  updateChartData() {
    if (this.draweeReport && this.draweeReport.operatedValues) {
      const {
        toExpireWithLead,
        expiredWithLead,
        toExpireWithDrawee,
        expiredWithDrawee,
      } = this.draweeReport.operatedValues;

      // Atualizando os dados do gráfico com os valores dinâmicos
      this.data = {
        labels: [''], // Pode ajustar conforme necessário
        datasets: [
          {
            data: [toExpireWithLead ?? 0],
            label: 'A vencer com o Cedente',
            backgroundColor: ['#1E1E1E'],
          },
          {
            data: [expiredWithLead ?? 0],
            label: 'Atrasado com o Cedente',
            backgroundColor: ['#CC3131'],
          },
          {
            data: [toExpireWithDrawee ?? 0],
            label: 'A vencer com o Sacado',
            backgroundColor: ['#a9a9a9'],
          },
          {
            data: [expiredWithDrawee ?? 0],
            label: 'Atrasado com o Sacado',
            backgroundColor: ['#F47500'],
          },
        ],
      };
    }
  }
}
