<div class="main-container">
  <div class="title">
    <h1>Liquidar valor do título</h1>
  </div>
  <p class="label">Preencha o valor total recebido do título.</p>
  <p class="label">
    Você já liquidou:
    <span class="label-value-liquited">
      {{ data.inputValue ?? 0 | currency : "BRL" }}</span
    >
  </p>

  <form class="value-info">
    <mat-form-field class="input" fill="outline">
      <input
        data-cy="liquidar-valor-parcial-titulo-input"
        currencyMask
        [options]=""
        [min]="minValue"
        matInput
        [formControl]="currencyFC"
        placeholder="R$ 0,00"
      />
      <mat-icon matSuffix color="primary">mode_edit</mat-icon>
    </mat-form-field>
  </form>
  <mat-error *ngIf="currencyFC.invalid">
    O valor deve ser superior a {{ minValue | currency : "BRL" }}
  </mat-error>
  <div class="button">
    <button class="actions-cancel" mat-flat-button mat-dialog-close="">
      Cancelar
    </button>
    <button
      data-cy="liquidar-valor-parcial-titulo-button"
      class="actions"
      mat-flat-button
      [disabled]="currencyFC.invalid"
      [ngClass]="{ 'button-disabled': currencyFC.invalid }"
      [ngStyle]="{
        'background-color': '#CC3179'
      }"
      (click)="updatePartial()"
    >
      Confirmar
    </button>
  </div>
</div>
