<div class="w-100 p-3 bg-white mb-2 border rounded">
  <form [formGroup]="formFilters" class="row align-items-center">
    <mat-form-field appearance="outline" class="my-form-field col-2">
      <mat-label>
        <mat-icon>search</mat-icon>
        Cedente
      </mat-label>
      <input
        class="input-autocomplete"
        placeholder="Digite o CNPJ ou a Razão Social"
        formControlName="nameActorOne"
        matInput
        type="text"
        [matAutocomplete]="autoLead"
      />
      <mat-autocomplete
        [displayWith]="displayActor.bind(this, 'actorOne')"
        #autoLead="matAutocomplete"
      >
        <mat-option
          *ngFor="let option of actorClientOptions['actorOne']"
          [value]="option.cnpj"
          >{{ option.name }} - {{ formatedCnpjLabel(option.cnpj) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" class="my-form-field col-2">
      <mat-label>
        <mat-icon>search</mat-icon>
        Sacado
      </mat-label>
      <input
        class="input-autocomplete"
        placeholder="Digite o CNPJ ou a Razão Social"
        formControlName="nameActorTwo"
        matInput
        type="text"
        [matAutocomplete]="autoDrawee"
      />
      <mat-autocomplete
        [displayWith]="displayActor.bind(this, 'actorTwo')"
        #autoDrawee="matAutocomplete"
      >
        <mat-option
          *ngFor="let option of actorClientOptions['actorTwo']"
          [value]="option.cnpj"
          >{{ option.name }} - {{ formatedCnpjLabel(option.cnpj) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" class="my-form-field col">
      <mat-label>
        <mat-icon>search</mat-icon>
        Título
      </mat-label>
      <input
        class="input-autocomplete"
        placeholder="Número do titulo"
        formControlName="numberInvoice"
        matInput
        type="text"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="my-form-field col-3">
      <mat-label>Data de Vencimento</mat-label>
      <mat-date-range-input [formGroup]="formFilters" [rangePicker]="picker">
        <input
          matStartDate
          placeholder="Data inicial"
          formControlName="dueDateInit"
        />
        <input matEndDate placeholder="Data final" formControlName="dueDateEnd" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      class="col-2 bg-black"
      (click)="onSubmit()"
    >
      <mat-icon class="mr-1">filter_alt</mat-icon>
      <span>Filtrar</span>
    </button>
    <button
      mat-stroked-button
      (click)="clearFilters()"
      type="button"
      class="col-1 mx-2 "
    >
      <mat-icon class="">filter_alt_off</mat-icon>
    </button>
  </form>
</div>
