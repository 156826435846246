import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createMask, InputmaskOptions } from '@ngneat/input-mask';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-modal-liquidated',
  templateUrl: './modal-liquidated.component.html',
  styleUrls: ['./modal-liquidated.component.scss'],
})
export class ModalLiquidatedComponent {
  formInCollection!: FormGroup;
  currencyFC: FormControl = new FormControl(0, {
    validators: [Validators.required],
    updateOn: 'change',
  });
  maxValue: number = 0;

  constructor(
    private buyerDashboardService: buyerDashboardService,
    private _snackBar: MatSnackBar,
    private notifierService: NotifierService,
    public dialogRef: MatDialogRef<ModalLiquidatedComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { installmentId: string; inputValue: string | number }
  ) {
    this.formInCollection = new FormGroup({
      value: new FormControl(null),
    });
    this.maxValue = (data.inputValue as number) - 0.01;
  }

  updateInCollection() {
    let installmentValue = this.currencyFC.value;

    this.buyerDashboardService
      .updatePartial({
        installment: this.data.installmentId,
        value: installmentValue as string,
      })
      .subscribe(() => {
        this.notifierService.showNotification({
          message: 'Dados Alterados.',
          alternative: 'Titulo alterado com Sucesso!',
          messageType: 'successNotifier',
        });
        this.dialogRef.close(true);
      });
  }
}
